<template>
	<div>
		<div>
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="商户名">
						<a-input v-model="search.name" placeholder="请输入商户名称"></a-input>
					</a-form-item>

					<a-form-item label="手机号">
						<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
					</a-form-item>
					
					<a-form-item label="应用分类">
						<div>
							<a-select style="width: 200px" placeholder="请选择" v-model="search.app_id">
								<a-select-option :value="0">全部类型</a-select-option>
								<a-select-option :value="item.app_id" v-for="(item,index) in app_list" :key="index">{{item.name}}</a-select-option>
							</a-select>
						</div>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="shop_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					
					<div slot="app" class="ft14 ftw500 cl-main" slot-scope="app,record">
						{{app ? app.name : '-' }}
					</div>
				 
					<div slot="relation" class="ft14 ftw500 cl-main" slot-scope="relation,record">
						{{relation.account.mobile}}{{relation.account.username ? '/'+relation.account.username : ''}}
					</div>
					
					<div class="flex alcenter center miniapp-table-logo" slot="auth" slot-scope="text,record">
						<img v-if="record.wx_face != null" :src="record.wx_face"/>
						<img v-else src="../../assets/image/logo48@2x.png"/>
						
						<div class="ml10">
							<div class="ft14  cl-main">小程序ID:{{record.wx_app_id ? record.wx_app_id : '暂无'}}</div>
							<div class="ft14 mt3 cl-notice">小程序名称:{{record.wx_name ? record.wx_name : '暂无'}}</div>
							<div class="ft14 mt3 cl-notice">运营单位:{{record.wx_principal_name ? record.wx_principal_name : '暂无'}}</div>
						</div>
					</div>
					
					<div class="flex alcenter center kefu-table-logo" slot="kefu" slot-scope="kefu,record">
						<div v-if="kefu != null" class="flex alcenter">
							<img v-if="kefu.qrcode != null" :src="kefu.qrcode"/>
							<img v-else src="../../assets/image/kefu.png"/>
							<div class="ml10">
								<div class="ft14  cl-main">客服名称:{{kefu.name ? kefu.name : '暂无'}}</div>
								<div class="ft14 mt3 cl-notice">客服手机号:{{kefu.mobile ? kefu.mobile : '暂无'}}</div>
							</div>
						</div>
						
						<div v-else> -</div>
					</div>

					<template slot="action" slot-scope="text,record">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="manageIt(record)">
											<i class="iconfont ft14 iconshop_operation01"></i>
											<span class="ml10">进入管理</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="renewMiniappAct(record)">
											<i class="iconfont ft14 iconnimipgrogram_operation04"></i>
											<span class="ml10">续费</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="appointKefuAct(record)">
											<i class="iconfont ft14 iconassignment"></i>
											<span class="ml10">指定客服</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="paymentSettingAct(record)">
											<i class="iconfont ft14 iconshezhi1"></i>
											<span class="ml10">支付设置</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="delShopAct(record)">
											<i class="iconfont ft14 icondelete"></i>
											<span class="ml10">删除</span>
										</a>
									</a-menu-item>
								</a-menu>
							</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
		</div>
		
		<div v-if="renewMiniappLoading">
			<renew-miniapp :shop_id="shop_id" :customer_service_id="customer_service_id" :visible="renewMiniappLoading" @cancel="cancelRenewMiniapp" @ok="okRenewMiniapp"></renew-miniapp>
		</div>
		<div v-if="appointKefuLoading">
			<appoint-kefu :shop_id="shop_id" :customer_service_id="customer_service_id" :visible="appointKefuLoading"
			@cancel="cancelAppointKefu" @ok="okAppointKefu"></appoint-kefu>
		</div>
		
		<div v-if="paymentSettingLoading">
			<payment-setting :shop_id="shop_id" :visible="paymentSettingLoading" @cancel="cancelPaymentSetting" @ok="okPaymentSetting"></payment-setting>
		</div>
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import renewMiniapp from'./components/renew.vue';
	import appointKefu from './components/index/modal/appointKefu.vue';
	import paymentSetting from './components/index/modal/paymentSetting.vue';
	export default {
		mixins: [listMixin],
		components:{
			renewMiniapp,
			appointKefu,
			paymentSetting,
		},
		data() {
			return {
				loading: false,
				renewMiniappLoading:false,
				appointKefuLoading:false,
				paymentSettingLoading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					name: '',
					mobile: "",
					app_id:0,
				},
				shop_id:0,
				customer_service_id:0,
				app_list:[],
				columns: [
					{title: 'ID编号',dataIndex: 'shop_id',align: 'center',ellipsis: true},
					{title: '应用分类',dataIndex: 'app',align: 'center',ellipsis: true,scopedSlots: {customRender: 'app'}},
					{title: '商铺名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '创始人',dataIndex: 'relation',align: 'center',scopedSlots: {customRender: 'relation'}},
					{title: '小程序',key: 'auth',align: 'center',width:400,scopedSlots: {customRender: 'auth'}},
					{title: '客服',dataIndex: 'kefu',align: 'center',width:250,scopedSlots: {customRender: 'kefu'}},
					{title: '有效期',dataIndex: 'expire_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getShopList',{
					name:this.search.name,
					mobile:this.search.mobile,
					app_id:this.search.app_id,
					status:2,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.datas=res.list;
					this.pagination.total=res.total;
					this.app_list=res.app_list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			manageIt(record){
				if(record.app.status==1){
					let base_url = record.app.production_address;
					window.open(base_url  + 'auth?shop_token='+ encodeURIComponent(record.relation.shop_token));
				}else{
					let base_url = record.app.development_address;
					window.open(base_url  + 'auth?shop_token='+ encodeURIComponent(record.relation.shop_token));
				}
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			renewMiniappAct(record){
				this.shop_id=record.shop_id;
				this.renewMiniappLoading=true;
			},
			cancelRenewMiniapp(){
				this.renewMiniappLoading=false;
			},
			okRenewMiniapp(){
				this.renewMiniappLoading=false;
				this.getLists();
			},
			
			appointKefuAct(record){
				this.shop_id=record.shop_id;
				this.customer_service_id=record.customer_service_id;
				this.appointKefuLoading=true;
			},
			cancelAppointKefu(){
				this.appointKefuLoading=false;
			},
			okAppointKefu(){
				this.appointKefuLoading=false;
				this.getLists();
			},
			
			paymentSettingAct(record){
				this.shop_id=record.shop_id;
				this.paymentSettingLoading=true;
			},
			cancelPaymentSetting(){
				this.paymentSettingLoading=false;
			},
			okPaymentSetting(){
				this.paymentSettingLoading=false;
				this.getLists();
			},
			
			delShopAct(record){
				this.$confirm({
					title:'删除后数据无法恢复，确认继续删除吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/admin/delShop',{
								shop_id:record.shop_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			}
			
		}
	}
</script>

<style>
	.miniapp-table-logo img{
		width: 80px;
		height: 80px;
		border-radius: 40px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
